<template>
  <LottieAnimationComponent
    :animationData="require(`@/assets/animations/not_found_animation_${parseInt(Math.random() * 4) + 1}.json`)"
    :text="$t('general.empty')"
  />
</template>
<script>
import LottieAnimationComponent from './LottieAnimationComponent.vue';

export default {
  name: 'EmptyListAnimationComponent',
  components: {
    LottieAnimationComponent,
  },
};
</script>
<style scoped>
</style>
