<template>
  <div v-if="animationData != null" class="lottie-wrapper">
    <div class="inner-wrapper">
      <div v-if="text != null" class='text'>{{text}}</div>
      <lottie-player :src="animationData"  background="transparent"  speed="1"  :style="{width: width+'px', height: height+'px'}"  loop  autoplay></lottie-player>
    </div>
  </div>
</template>
<script>
import '../helpers/lottie-player';

/* eslint-disable no-console */
export default {
  name: 'LottieAnimationComponent',
  components: {
  },
  props: {
    animationData: {
      type: Object,
      default: null,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    text: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style scoped>
  .lottie-wrapper {
    width: 100%;
    margin-top: 20px;
  }
  .inner-wrapper {
    width: 200px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .text {
    color: var(--color_light_gray);
    font-size: 14px;
  }
</style>
